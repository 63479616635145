import { Component, inject } from '@angular/core';
import { SupportDialogComponent } from './support-dialog/support-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [DatePipe]
})
export class FooterComponent {
  private _dialog = inject(MatDialog);

  public currentDate: Date = new Date();

  public openSupport(): void {
    this._dialog.open(SupportDialogComponent, {
      width: '400px',
      panelClass: 'support-dialog'
    });
  }
}
