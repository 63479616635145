import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

// import { enableProdMode, importProvidersFrom } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { environment } from './environments/environment';
// import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { AppRoutingModule } from './app/app-routing.module';
// import { provideAnimations } from '@angular/platform-browser/animations';
// import { ExternalModule } from './app/external/external.module';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
