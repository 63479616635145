import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'ext/booking', pathMatch: 'full' },
  // { path: "landing", component: LandingComponent },
  // Disabled for now until we further develop the app
  // {
  //   path: 'app',
  //   loadChildren: () =>
  //     import('./authenticated/authenticated.module').then(
  //       (m) => m.AuthenticatedModule
  //     ),
  // },
  {
    path: 'ext',
    loadChildren: () => import('./external/external.routes').then((m) => m.routes)
  },
  { path: 'booking', redirectTo: 'ext/booking', pathMatch: 'full' },
  {
    path: 'locate',
    loadChildren: () => import('./locate/locate.routes').then((m) => m.routes)
  },
  { path: 'located', loadComponent: () => import('./components/located/located.component').then((c) => c.LocatedComponent) }
];
